import React, { MouseEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import * as Accordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { MAX_SCREEN_SIZE } from '@/lib/constants';
import { onImageError } from '@/utils/sentry/onImageError';
import { Link } from '@/components/CustomLink';
import { Image } from '@/components/Image';
import { Button } from '../Button/Button';
const NavBarStyles = {
  maxWidth: '1440px',
  minHeight: '73px',
  visibleScrollValue: 300
};
type NavigationTab = {
  text?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  active?: boolean;
};
type TopNavLink = {
  text?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
};
type MainNavLink = {
  text?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  megaMenuContent: ReactNode;
  hasMegaMenu?: boolean;
  isMobileVersion?: boolean;
};
type Props = {
  navigationTabs: NavigationTab[];
  topNavLinks?: TopNavLink[];
  logoImage?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  mobileLogoImage?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  logoWidth: number;
  mobileLogoWidth: number;
  logoAlt: string;
  logoLink?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  mainNavLinks?: MainNavLink[];
  ctaLink?: {
    href: string;
    onClick(e: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  ctaText?: string;
};
type HandleMobileLinkClickProps = {
  event: React.MouseEvent<HTMLAnchorElement>;
  mainNavLink: MainNavLink;
  index: number;
};
function getFlagEmoji(locale: Intl.Locale): string | undefined {
  const region = locale.region;
  if (region == null) return;
  const codePoints = region.toUpperCase().split('').map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
export function MegaMenuNavigation({
  navigationTabs,
  topNavLinks,
  logoImage,
  mobileLogoImage,
  logoAlt,
  logoWidth,
  mobileLogoWidth,
  logoLink,
  mainNavLinks,
  ctaText,
  ctaLink
}: Props) {
  const useSticky = () => {
    const stickyRef = useRef<HTMLDivElement | null>(null);
    const [sticky, setSticky] = useState(false);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
      if (stickyRef.current) {
        setOffset(stickyRef.current.offsetTop);
      }
    }, [stickyRef]);
    useEffect(() => {
      const handleScroll = () => {
        if (stickyRef.current) {
          setSticky(window.scrollY > offset);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [offset]);
    return {
      stickyRef,
      sticky
    };
  };
  const {
    sticky,
    stickyRef
  } = useSticky();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const topNavRef = useRef<HTMLDivElement | null>(null);
  const bottomNavRef = useRef<HTMLDivElement | null>(null);
  const columnsRef = useRef<HTMLDivElement | null>(null);
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const [activeLinkIndex, setActiveLinkIndex] = useState<number | null>(null);
  const [isMenuAnimated, setIsMenuAnimated] = useState<boolean>(true);
  const [activeMobileItems, setActiveMobileItems] = useState<string[]>([]);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [previousScrollY, setPreviousScrollY] = useState<number>(0);
  const [isMobileCountryAccordionOpen, setIsMobileCountryAccordionOpen] = useState<boolean>(false);
  const handleLinkClick = (index: any) => {
    const isSameIndex = index === activeLinkIndex;
    setActiveLinkIndex(isSameIndex ? null : index);
    setIsMenuAnimated(isSameIndex ? true : activeLinkIndex === null);
  };
  const handleMobileLinkClick = ({
    event,
    mainNavLink,
    index
  }: HandleMobileLinkClickProps) => {
    if (!mainNavLink.hasMegaMenu) return;
    //Prevents when user clicks an option if has a link set do not redirect, this affects the normal functionality of the Accordion so values should be set in this way.
    event.preventDefault();
    setActiveMobileItems(prev => {
      const selectedItem = `item${index}`;
      const isItemAlreadySet = prev.includes(selectedItem);
      //removes item if was already set if not, add it to the array
      return isItemAlreadySet ? prev.filter(element => element !== selectedItem) : [...prev, selectedItem];
    });
    mainNavLink.link?.onClick?.(event);
  };
  const handleChangeCountry = (value: string[]) => {
    setIsMobileCountryAccordionOpen(value.includes('countries'));
  };
  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', mobileNavOpen);
    if (mobileNavOpen) setActiveLinkIndex(null);
  }, [mobileNavOpen]);
  const headerHeight = useMemo(() => {
    return headerRef.current?.offsetHeight || 0;
  }, [headerRef.current]);
  const {
    locale,
    defaultLocale,
    locales,
    domainLocales
  } = useRouter();
  const currentLocale = locale ?? defaultLocale;
  const countries = useMemo(() => {
    if (locales == null) return [];
    const omitLocales = ['en-FR', 'en-NL', 'en-IT', 'en-ES', 'en-MX', 'en-DE', 'en-AT', 'en-SE', 'en-NO', 'en-DK'];
    return locales.filter(loc => !omitLocales.includes(loc)).map(locale => new Intl.Locale(locale)).flatMap(locale => {
      if (locale.region == null) return [];
      const sortKey = new Intl.DisplayNames([locale], {
        type: 'region'
      }).of(locale.region);
      let name = new Intl.DisplayNames([locale], {
        type: 'region'
      }).of(locale.region);
      const flag = getFlagEmoji(locale);
      const domainLocale = domainLocales?.find(domainLocale => domainLocale.defaultLocale === locale.toString());
      if (sortKey == null || name == null || flag == null) return [];
      const hrefProtocol = domainLocale?.http ? 'http' : 'https';
      const href = domainLocale?.domain == null ? '/' : `${hrefProtocol}://${domainLocale.domain}`;
      name = name === 'United Arab Emirates' ? 'UAE' : name;
      return [{
        locale,
        href,
        name,
        flag,
        sortKey
      }];
    }).sort((a, b) => a.sortKey.localeCompare(b.sortKey));
  }, [domainLocales, locales]);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY < previousScrollY) {
        setIsHidden(false);
      } else if (currentScrollY > NavBarStyles.visibleScrollValue) {
        setIsHidden(true);
      }
      setPreviousScrollY(currentScrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [previousScrollY]);
  useEffect(() => {
    //Adjust header size to show correctly the position of the columns when resize window
    const updateSize = () => {
      const currentHeaderWidth = headerRef.current?.offsetWidth || 0;
      //Remove Active Mobile Items to prevent Issues
      if (currentHeaderWidth > MAX_SCREEN_SIZE) {
        setMobileNavOpen(false);
        setActiveMobileItems([]);
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();

    // Define the click event listener
    const handleClick = (event: Event) => {
      // Check if the clicked element has any of the specified class names
      const target = (event.target as HTMLElement);
      if (target.classList.contains('link-column-click')) {
        setActiveLinkIndex(null);
        setMobileNavOpen(false);
        setActiveMobileItems([]);
      }
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('click', handleClick);
    };
  }, []);
  return <>
			<header className={clsx(`w-full flex flex-col bg-white z-30 transition-all fixed !left-0 !top-0 lg-plus:max-h-full`, mobileNavOpen ? 'fixed inset-0 top-0 lg-plus:h-[132px] overflow-scroll' : '', isHidden && activeLinkIndex === null ? '  pointer-events-none translate-y-[-100%] duration-1000' : ' translate-y-0 duration-500')} ref={headerRef}>
				<div className="h-9 border-b border-gray-1200 bg-gray-1400 px-0  md:px-8 xl:px-16 xl-plus:px-16 flex items-center justify-center" ref={topNavRef}>
					<nav className="flex h-9 w-full  justify-between" style={{
          maxWidth: NavBarStyles.maxWidth
        }}>
						<ul className="flex h-full w-full text-eyebrow font-medium uppercase lg-plus:w-80">
							{navigationTabs?.map(({
              text,
              link,
              active
            }, i) => {
              if (!text || !link) return null;
              return <li className={clsx('box-content block h-full w-1/2 border-b', active ? 'border-white bg-white' : 'border-transparent bg-transparent')} key={i}>
										<Link {...link} className={clsx('block h-full text-center leading-9', active ? 'text-black-100' : 'border-x border-transparent text-gray-700 hover:border-gray-1200 hover:bg-gray-1300 hover:text-gray-400')}>
											{text}
										</Link>
									</li>;
            })}
						</ul>

						<ul className="hidden h-full font-medium lg-plus:flex ">
							{topNavLinks?.map((topNavLink, i) => {
              return <li key={i} className="h-full px-3">
										<Link href={topNavLink.link?.href ?? '#'} onClick={topNavLink.link?.onClick} target={topNavLink.link?.target} className="block h-full text-eyebrow leading-9 text-gray-700 hover:text-gray-400">
											{topNavLink.text}
										</Link>
									</li>;
            })}
							<li className="group relative flex h-full cursor-pointer items-center border-x border-transparent pl-3 text-gray-700 after:h-1 after:w-2 after:border-4 after:border-b-0 after:border-x-transparent after:border-t-gray-900 hover:border-gray-1200 hover:bg-gray-1300 hover:text-gray-400">
								{currentLocale && <span className="text-lg mr-2">
										{getFlagEmoji(new Intl.Locale(currentLocale))}
									</span>}
								<ul className="absolute top-full right-[-1px] z-[31] hidden h-72 w-44 overflow-auto bg-white shadow-lg group-hover:block">
									{countries?.map((country, i) => {
                  return <li key={i} className="h-8 font-normal text-xs text-gray-700">
												<Link href={country.href} locale={country.locale.toString()} className="flex items-center bg-transparent py-2 px-3 transition-colors hover:bg-gray-1300">
													<span className="mr-1 text-[18px] h-[18px]">{country.flag}</span>
													{country.name}
												</Link>
											</li>;
                })}
								</ul>
							</li>
						</ul>
						<div className="flex lg-plus:hidden md:w-full"></div>
					</nav>
				</div>
				<div className={clsx('w-full bg-white', activeLinkIndex !== null ? 'border-b border-b-gray-1000' : '')}>
					<div ref={stickyRef} className={clsx(`flex flex-1 md:flex-none flex-col bg-white z-30 mx-auto px-6 md:px-8 xl:px-16 xl-plus:px-0`, sticky && mobileNavOpen ? 'h-full' : '', mobileNavOpen ? 'min-h-0 max-h-16' : '', isMobileCountryAccordionOpen ? ' md:!flex-1' : '')} style={{
          transitionProperty: 'transform, opacity, height'
        }}>
						<nav className="flex w-full items-center self-center" style={{
            maxWidth: NavBarStyles.maxWidth,
            minHeight: NavBarStyles.minHeight
          }} ref={bottomNavRef}>
							<div className="min-w-14 max-w-[190px] w-1/3 flex-1 lg-plus:min-w-[156px] pr-6  lg-plus:h-10 lg-plus:mb-0.5	">
								<Link href={logoLink?.href ?? '#'} onClick={logoLink?.onClick} target={logoLink?.target} data-sentry-element="Link" data-sentry-source-file="MegaMenuNavigation.tsx">
									{logoImage?.url && <Image loading="eager" src={logoImage.url} alt={logoAlt} width={logoWidth} height={logoWidth / (logoImage?.dimensions?.width / logoImage?.dimensions?.height)} className="hidden lg-plus:block" onError={onImageError} />}
									{mobileLogoImage?.url && <Image loading="eager" src={mobileLogoImage.url} alt={logoAlt} width={mobileLogoWidth} height={mobileLogoWidth / (mobileLogoImage?.dimensions?.width / mobileLogoImage?.dimensions?.height)} className="block lg-plus:hidden h-8" onError={onImageError} />}
								</Link>
							</div>
							<div className="hidden h-full lg-plus:flex ">
								{mainNavLinks?.map((mainNavLink, i) => <div key={i} className="w-full flex flex-col  ">
										<div key={i} className="flex flex-col w-full h-full z-30 ">
											<div key={i} className="flex content-center h-full ">
												<Link href={mainNavLink.link?.href ?? '#'} onClick={e => {
                      if (!mainNavLink.hasMegaMenu) return;
                      e.preventDefault();
                      handleLinkClick(i);
                      mainNavLink.link?.onClick?.(e);
                    }} target={mainNavLink.link?.target} className="flex flex-col justify-center text-bc-black text-xs font-medium mx-3">
													<div className="flex justify-center items-center gap-1">
														<span className={clsx('border-b-2 border-transparent hover:border-bc-blue', activeLinkIndex === i ? ' !border-bc-blue' : '')}>
															{mainNavLink.text}
														</span>

														{mainNavLink.hasMegaMenu && <div className={clsx('w-1.5 border-[3px] border-b-0 border-x-transparent border-t-gray-900  transition-transform duration-[400ms]', activeLinkIndex === i ? 'rotate-180' : '')} />}
													</div>
												</Link>
											</div>
										</div>
									</div>)}
							</div>

							<div className="flex items-center gap-6 ml-auto    md:w-1/3  justify-end">
								{ctaText && <div>
										<Button variant="filled" color="primary" size="small" link={ctaLink} showIcon={false} className="lg-plus:hidden">
											{ctaText}
										</Button>

										<Button variant="filled" color="primary" size="default" link={ctaLink} showIcon={false} className="hidden lg-plus:block">
											{ctaText}
										</Button>
									</div>}

								<button onClick={() => setMobileNavOpen(!mobileNavOpen)} className="block w-7 lg-plus:hidden">
									<span className="sr-only">{mobileNavOpen ? 'close menu' : 'open menu'}</span>
									{mobileNavOpen ? <svg height="18" width="19" className="mx-auto fill-gray-700">
											<title>Close Nav Icon</title>
											<g fillRule="evenodd">
												<path d="M.66116524 16.4246212L16.9246212.16116524l1.41421356 1.41421356L2.0753788 17.83883476z"></path>
												<path d="M18.33883476 16.4246212L2.0753788.16116524.66116524 1.5753788 16.9246212 17.83883476z"></path>
											</g>
										</svg> : <svg height="18" width="28" className="fill-gray-700">
											<g fillRule="evenodd">
												<path d="M0 0h28v2H0zM0 8h28v2H0zM0 16h28v2H0z"></path>
											</g>
										</svg>}
								</button>
							</div>
						</nav>
					</div>
					<nav className={`flex-col bg-white overflow-auto ${mobileNavOpen ? 'flex' : 'hidden'}`}>
						<Accordion.Root type="multiple" className="flex-1 border-t border-gray-900 flex flex-col gap-1 py-2" onValueChange={value => setActiveMobileItems(value)} value={activeMobileItems} data-sentry-element="unknown" data-sentry-source-file="MegaMenuNavigation.tsx">
							{mainNavLinks?.map((mainNavLink, i) => <div key={i} className="flex flex-col">
									<Accordion.Item value={'item' + i} key={i} className="flex flex-col">
										<Accordion.Trigger className="group w-full text-left">
											<Link href={mainNavLink.link?.href ?? '#'} onClick={event => handleMobileLinkClick({
                    event,
                    mainNavLink,
                    index: i
                  })} target={mainNavLink.link?.target} className="relative flex items-center  py-2 mx-6 md:mx-8  2xl:mx-auto text-xs text-bc-black gap-1 font-medium">
												<span className={clsx(activeMobileItems.includes(`item${i}`) ? 'border-b-2 border-bc-blue' : '')}>
													{mainNavLink.text}
												</span>

												{mainNavLink.hasMegaMenu && <div className="w-1.5 border-[3px] border-b-0 border-x-transparent border-t-gray-900  transition-transform duration-[400ms] group-data-[state=open]:rotate-180"></div>}
											</Link>
										</Accordion.Trigger>
										{mainNavLink.hasMegaMenu && <Accordion.AccordionContent className="w-full pt-2 pb-8 space-y-8 flex ">
												<div className="megamenu-container w-full">
													{mainNavLink.megaMenuContent}
												</div>
											</Accordion.AccordionContent>}
									</Accordion.Item>
								</div>)}
						</Accordion.Root>

						<div className="border-t border-gray-900 py-2 px-6 md:px-8 text-bc-black text-xs relative flex gap-1 flex-col font-medium">
							{topNavLinks?.map((topNavLink, i) => <Link key={`${topNavLink.text}-${i}`} href={topNavLink?.link?.href ?? ''} className="py-2">
									{topNavLink.text}
								</Link>)}
						</div>
						<Accordion.Root type="multiple" onValueChange={handleChangeCountry} data-sentry-element="unknown" data-sentry-source-file="MegaMenuNavigation.tsx">
							<Accordion.Item value={'countries'} data-sentry-element="unknown" data-sentry-source-file="MegaMenuNavigation.tsx">
								<Accordion.Trigger className="group w-full text-left" asChild={true} data-sentry-element="unknown" data-sentry-source-file="MegaMenuNavigation.tsx">
									<button className="w-full flex items-center border-t border-gray-900  py-2 px-6 md:px-8 text-xs text-bc-black gap-1 font-medium">
										<span className="py-2">
											{currentLocale && <span className="text-sm mr-4">
													{getFlagEmoji(new Intl.Locale(currentLocale))}
												</span>}
											Change country
										</span>

										<div className="w-1.5 border-[3px] border-b-0 border-x-transparent border-t-gray-900 transition-transform duration-[400ms] group-data-[state=open]:rotate-180"></div>
									</button>
								</Accordion.Trigger>
								<Accordion.AccordionContent className="px-6 md:px-8 pt-5 pb-8 space-y-8 flex-1" data-sentry-element="unknown" data-sentry-source-file="MegaMenuNavigation.tsx">
									<ul className="space-y-3 flex flex-col gap-1">
										{countries?.map((country, i) => {
                    return <li key={i} className="h-fit font-normal text-bc-black">
													<Link href={country.href} locale={country.locale.toString()} className="flex items-center text-xs text-bc-black">
														<span className="mr-4 text-lg-plus">{country.flag}</span>
														<span className="w-28">{country.name}</span>
													</Link>
												</li>;
                  })}
									</ul>
								</Accordion.AccordionContent>
							</Accordion.Item>
						</Accordion.Root>
					</nav>
				</div>
				<div className="hidden h-full w-3/12 lg-plus:flex -z-20">
					{mainNavLinks?.map((mainNavLink, i) => <div key={i} className="bg-white">
							{mainNavLink.hasMegaMenu && <div className={clsx('left-0 w-full h-fit max-h-[80vh] absolute', activeLinkIndex === i ? 'translate-y-0 overflow-auto' : 'translate-y-[-100%] overflow-hidden  pointer-events-none')} ref={columnsRef} style={{
            top: `${headerHeight}px`,
            transition: isMenuAnimated ? 'transform 250ms ease' : 'transform 0ms ease'
          }}>
									{mainNavLink.megaMenuContent}
								</div>}
						</div>)}
				</div>
			</header>
			<div className={clsx(activeLinkIndex !== null ? 'opacity-50' : 'opacity-0 pointer-events-none', 'z-[1] !block top-0 w-full lg-plus:hidden h-screen bg-bc-black fixed right-0 transition-all duration-300 ')} onClick={() => {
      setActiveLinkIndex(null);
      setIsMenuAnimated(true);
      setMobileNavOpen(false);
    }} />
			{/* Fallback to prevent CLS */}
			<div style={{
      height: '108px'
    }} />
		</>;
}